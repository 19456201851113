import React from "react";

function FifthArticle() {
  const images = [
    "/img/articles/5/5.jpg",
    "/img/articles/5/6.jpg",
    "/img/articles/5/7.jpg",
    "/img/articles/5/8.jpg",
    "/img/articles/5/9.jpg",
    "/img/articles/5/10.jpg",
    "/img/articles/5/11.jpg",
    "/img/articles/5/12.jpg",
    "/img/articles/5/13.jpg",
    "/img/articles/5/14.jpg",
  ];
  return (
    <>
      <h1>Гарантии</h1>

      <p className="mt-1">
        Самый важный момент в покупке любого товара в интернете это ГАРАНТИИ и
        уверенность в получении товара надлежащего качества.
      </p>

      <img
        src={process.env.PUBLIC_URL + "/img/articles/5/main.jpg"}
        className="large"
        alt=""
      />

      <h3>
        Важный закономерный вопрос возникающий перед покупкой - это "Какие
        гарантии?"
      </h3>

      <ul>
        <li>
          <a href="https://t.me/+ivD22ai8Y6tiNmMy" className="bold">
            Отзывы о нашей работе
          </a>
        </li>

        <li>
          <a className="bold" href="https://t.me/travmat_boevoe_pistolet">
            Чат наших покупателей:
          </a>
        </li>
      </ul>

      <div className="textBlock">
        <p>
          Является офертой. Оплата выбранного товара по прайсу или запрос на
          оказание услуги, заявленной в переписке{" "}
          <a href="https://t.me/YAKUB0VlCH_OPERATORS">@YAKUB0VlCH_OPERATORS</a>{" "}
          , представляют собой акцепт оферты по оказанию услуг, поименованных в
          прайсе. Принятие Заказчиком оказанной услуги или покупка изделий
          фиксируется сообщением в чате{" "}
          <a href="https://t.me/YAKUB0VlCH_OPERATORS">@YAKUB0VlCH_OPERATORS</a>{" "}
          в произвольной форме. Исполнитель не несёт ответственности за
          дальнейшее использование Заказчиком полученного от Исполнителя
          продукта (изделия). Магазин продает товары без документов для личного
          пользования физических лиц в целях развития навыков обращения с
          оружием и самообороны. Мы верим, что вы не будете применять наши
          товары в корыстных целях.
        </p>
      </div>

      <h3 className="mt-4">
        Ниже перечислены причины почему нужно работать именно с нами и можно
        доверять.
      </h3>

      <ul>
        <li>
          Мошенников развелось крайне много и в первую очередь необходимо
          убедится что товар который вам предлагают реален и имеется в наличии.
          Самый простой и верный способ проверить это - напишите любую фразу и
          продавец снимает фото вашего товара на фоне листа бумаги с надписью{" "}
        </li>
      </ul>

      <div className="rowCenter">
        <img
          src={process.env.PUBLIC_URL + "/img/articles/5/1.jpg"}
          className="mediumImage"
          alt=""
        />
      </div>

      <ul>
        <li>
          Важный момент - Отзывы. Отзывы покажут сколько человек совершали
          покупки, естественно из 50 купивших может один только оставит отзыв,
          но наиболее важно не количество, а начало публикаций этих отзывов и
          понять что продавец работает достаточно длительный промежуток времени,
          а не однодневка или фейк.
        </li>

        <li>
          Мы не берем предоплату! Оплата строго по факту проверки и получения
          заказа.(<span className="bold">Не актуально с 30.01.22</span>)
        </li>

        <li>
          Мы оказываем всецелую и полноценную поддержку покупателю до самого
          подъема клада, подскажем, поможем, у нас не бывает ненаходов.
          Справится даже ребенок. Не бывает ситуаций, что после покупки мы
          присылаем информацию о кладе и ищи как хочешь. Нет, полностью доводим
          сделку до самого конца. Даже в случае 100% ненахода, мы предоставим
          новый клад идентичный вашему заказу!{" "}
          <span className="bold">
            Покупая товар у нас вы в любом случае получаете свой заказ!
          </span>
        </li>

        <li>
          здержки есть издержки, если после подъема, при проверке товара
          обнаружите какую либо неисправность (Редкость), то мы сделаем либо
          перезаклад точно такого же изделия за наш счет в ближайшее время, либо
          предложим аналоги!
        </li>

        <li>
          Достаточно важный момент это опыт работы. Мы ведем успешную работу уже
          свыше 5 лет и до деталей знаем свое дело. Достаточно масштабно
          расширили сферу своего влияния и повысили узнаваемость в интернете.
          Ввиду некоторых событий вышли в полноценную самостоятельную работу на
          площадке телеграм.
        </li>

        <li>
          Уникальное преимущество над любым другим шопом оружия - это чат
          покупателей. Ни у кого и ни у одного магазина на площадке телеграм нет
          чата покупателей, где обсуждается околооружейная тема и любой
          малознающий камрад может задать вопрос, после получить конструктивный
          ответ от других покупателей. (
          <span className="bold">
            Доступ к чату получают только покупатели по коду покупки, никаких
            исключений нет и не будет
          </span>
          )
        </li>
      </ul>

      <img
        src={process.env.PUBLIC_URL + "/img/articles/5/3.jpg"}
        className="large"
        alt=""
      />

      <ul>
        <li>
          Заключительный момент и очень важный! Мы предоставляем очень четкое
          описание, фото и видео с места где находится клад. Никаких мутных фото
          и глупых описаний, строго по делу и максимально конструктивно!
        </li>
      </ul>

      <div className="row-images">
        {images.map((image, index) => (
          <img key={index} src={process.env.PUBLIC_URL + image} alt="" />
        ))}
      </div>

      <div className="textBlock mt-2">
        <p>
          Вот 8 основных причин работать с нашим магазином. Помните, что
          мошенников в наше непростое время очень много! Единственный оператор у
          кого можно сделать заказ{" "}
          <a href="https://t.me/YAKUB0VlCH_OPERATORS">@YAKUB0VlCH_OPERATORS</a>{" "}
          любой другой фейк!
        </p>
      </div>
    </>
  );
}

export default FifthArticle;
