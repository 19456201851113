import React from "react";
import { useState } from "react";

function CombatPistolsPart() {
  const products = [
    {
      title: "ПМ (Переделка с СХП)",
      caliber: "9x18",
      price: "30.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "pm-shp.png",
    },
    {
      title: "MP-446C Viking",
      caliber: "3x19",
      price: "35.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "viking.png",
    },
    {
      title: "ПМ ПБС (Переделка с СХП)",
      caliber: "9x18",
      price: "39.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "pm-shp.png",
    },
    {
      title: "ПМ (Заводской)",
      caliber: "9x18",
      price: "42.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "pm-zavod.jpg",
    },
    {
      title: "Пистолет Бесшумный ПБ",
      caliber: "9x18",
      price: "60.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "pb.jpg",
    },
    {
      title: "Walther PP",
      caliber: "7.62x17",
      price: "65.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "waltherpp.png",
    },
    {
      title: "Bersa Thunder 9PRO Mate",
      caliber: "9x19",
      price: "80.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "bersa.png",
    },
    {
      title: "ТТ",
      caliber: "7.62x25",
      price: "90.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "tt.png",
    },
    {
      title: "Glock19X",
      caliber: "9x19",
      price: "110.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "glock19x.jpg",
    },
    {
      title: "CZ 75",
      caliber: "9x19",
      price: "120.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "cz75.png",
    },
    {
      title: "АПС",
      caliber: "9x18",
      price: "130.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "aps.png",
    },
    {
      title: "Mauser C96",
      caliber: "7.62x25",
      price: "211.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "mauser.png",
    },
    {
      title: "Кольт M1911",
      caliber: ".45ACP",
      price: "220.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "coltm1911.jpg",
    },
    {
      title: "Glock19 Gen3",
      caliber: "9x19",
      price: "360.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "glock19gen3.jpg",
    },
    {
      title: "Glock17 Gen4",
      caliber: "9x19",
      price: "450.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "glock17gen4.jpg",
    },
    {
      title: "Glock19 Gen4",
      caliber: "9x19",
      price: "480.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "glock19gen4.jpg",
    },
    {
      title: "Glock42",
      caliber: "9x17",
      price: "500.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "glock42.jpg",
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const pageCount = Math.ceil(products.length / itemsPerPage);

  const showPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderPaginationLinks = () => {
    const links = [];
    for (let i = 1; i <= pageCount; i++) {
      links.push(
        <a
          href="#"
          key={i}
          className={`linkk ${i === currentPage ? "active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            showPage(i);
          }}
        >
          {i}
        </a>
      );
    }
    return links;
  };

  const renderProducts = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return products.slice(startIndex, endIndex).map((product, index) => (
      <div className="block" key={index}>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/catalogPage/combatPistols/" +
            product.image
          }
          alt=""
        />
        <div className="textPart">
          <h3>
            {product.title} ({product.caliber})
          </h3>
          <h4>Комплектация</h4>
          <ul>
            {product.equipment.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <div className="row">
            <p className="price">{product.price} ₽</p>
            <a
              href={`https://t.me/YAKUB0VlCH_OPERATORS?text=Доброго%20времени%20суток.%20Интересует%20травматический%20пистолет%20модели%20${encodeURIComponent(
                product.title
              )}`}
              target="_blank"
              className="sendTo"
              rel="noopener noreferrer"
            >
              Заказать у оператора
            </a>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="container">{renderProducts()}</div>
      <div className="pagination">{renderPaginationLinks()}</div>
    </>
  );
}

export default CombatPistolsPart;
