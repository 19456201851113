import React from "react";

function EiethArticle() {
  return (
    <>
      <h1>Как ухаживать за травматическим оружием?</h1>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/8/main.png"}
        alt=""
        className="large"
      />

      <p>
        Чтобы огнестрельное оружие ограниченного поражения (ОООП) работало
        надёжно, сохраняло эксплуатационные свойства и всегда было готово к
        применению, нужно ухаживать за ним – чистить и смазывать.
      </p>

      <h3>Для чистки и смазки ОООП необходимы:</h3>

      <ul>
        <li>
          Набор для чистки травматического оружия с гибкими ершом, протиркой и
          щёткой;
        </li>
        <li>Состав для удаления нагара;</li>
        <li>Нейтральное оружейное масло или оружейная смазка;</li>
        <li>Патчи или ветошь;</li>
        <li>Защитное покрытие для рабочей поверхности;</li>
        <li>Нитриловые перчатки для защиты кожи рук.</li>
      </ul>

      <p>
        Канал ствола большинства короткоствольного ОООП имеет сужение и
        преграды, которые исключают выстрел твёрдым предметом. Из-за этого
        использование обычных пистолетных наборов для чистки ОООП невозможно.
        Стоит найти наборы с гибкими ершом и протиркой, предназначенные для
        чистки травматического оружия. Поскольку для стрельбы из ОООП
        используются патроны с резиновой пулей, во время чистки необходимо
        избавляться только от нагара, частиц несгоревшего пороха и остатков
        резины.
      </p>

      <h3>Как почистить и смазать травматический пистолет:</h3>
      <ul>
        <li>Убедитесь, что оружие разряжено!</li>
        <li>
          Проведите неполную разборку согласно руководству по эксплуатации;
        </li>
        <li>
          Нанесите средство для удаления нагара в канал ствола и патронник;
        </li>
        <li>
          Прочистите канал ствола с помощью протирки и ветоши, воспользуйтесь
          ершом для удаления частиц резины и несгоревшего пороха;
        </li>
        <li>
          Очистите другие детали оружия от видимых загрязнений с помощью щётки и
          ветоши, смоченной в средстве для удаления нагара;
        </li>
        <li>
          Нанесите тонкий слой нейтрального оружейного масла на очищенные
          поверхности оружия;
        </li>
        <li>Соберите оружие, убедитесь в работе механизмов.</li>
      </ul>

      <p>
        Не допускайте хранения оружия в загрязнённом состоянии. Чистка и смазка
        после стрельбы производится при первой возможности – так вы защитите
        оружие от коррозии и продлите срок его службы. Если вы не стреляете из
        оружия, но постоянно носите его в целях самообороны, стоит раз в неделю
        проводить неполную разборку, чистку и смазку оружия для профилактики.
      </p>
    </>
  );
}

export default EiethArticle;
