import React from "react";
import CatalogPart from "../../components/catalogPart/catalogPart.jsx";
import "./css/catalog.css";
import { Helmet } from "react-helmet";

function Catalog() {
  return (
    <>
      <Helmet>
        <title>Каталог</title>
      </Helmet>
      <main className="catalogPage">
        <CatalogPart />
      </main>
    </>
  );
}

export default Catalog;
