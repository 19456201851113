import React from "react";
import { useState } from "react";
function CombatRiflesPart() {
  const products = [
    {
      title: "АК47М",
      caliber: "5.45x39",
      price: "60.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "АКМ",
      caliber: "7.62x39",
      price: "90.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "АК103",
      caliber: "7.62x39",
      price: "100.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "АК12",
      caliber: "5.45x39",
      price: "120.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "АКС-74У",
      caliber: "5.45x39",
      price: "140.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "SWC RCS-201s",
      caliber: "9x19",
      price: "160.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "АК102",
      caliber: "5.45x45",
      price: "180.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "Colt AR15",
      caliber: "223Rem",
      price: "360.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "BUSHMASTER XM15",
      caliber: "223Rem",
      price: "360.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
    {
      title: "STEYR-MANNCLICHER AUG",
      caliber: "9x19",
      price: "390.000",
      equipment: [
        "100 патронов",
        "Дополнительный магазин",
        "Набор для чистки",
        "Кейс",
      ],
      image: "",
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const pageCount = Math.ceil(products.length / itemsPerPage);

  const showPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderPaginationLinks = () => {
    const links = [];
    for (let i = 1; i <= pageCount; i++) {
      links.push(
        <a
          href="#"
          key={i}
          className={`linkk ${i === currentPage ? "active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            showPage(i);
          }}
        >
          {i}
        </a>
      );
    }
    return links;
  };

  const renderProducts = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return products.slice(startIndex, endIndex).map((product, index) => (
      <div className="block" key={index}>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/catalogPage/combatRiffles/" +
            product.image
          }
          alt=""
        />
        <div className="textPart">
          <h3>
            {product.title} ({product.caliber})
          </h3>
          <h4>Комплектация</h4>
          <ul>
            {product.equipment.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <div className="row">
            <p className="price">{product.price} ₽</p>
            <a
              href={`https://t.me/YAKUB0VlCH_OPERATORS?text=Доброго%20времени%20суток.%20Интересует%20травматический%20пистолет%20модели%20${encodeURIComponent(
                product.title
              )}`}
              target="_blank"
              className="sendTo"
              rel="noopener noreferrer"
            >
              Заказать у оператора
            </a>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="container">{renderProducts()}</div>
      <div className="pagination">{renderPaginationLinks()}</div>
    </>
  );
}

export default CombatRiflesPart;
