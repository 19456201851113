import React from "react";
import { useParams } from "react-router-dom";
import "./css/articlePage.css";
import FirstArticle from "./articles/infoAboutShop.jsx";
import SecondArticle from "./articles/howToDelivery.jsx";
import ThirdArticle from "./articles/howToGet.jsx";
import FourthArticle from "./articles/taxiDelivery.jsx";
import FifthArticle from "./articles/warranty.jsx";
import SixthArticle from "./articles/travmat.jsx";
import SeventhArticle from "./articles/cancelDelivery.jsx";
import EiethArticle from "./articles/howToLookFromTravmat.jsx";
import NinthArticle from "./articles/howToFireOOOP.jsx";
import CalibreArticle from "./articles/calibreArticle.jsx";
import UncorservationArticle from "./articles/uncorservationArticle.jsx";
import CarryingWeapon from "./articles/carryingWeapon.jsx";
import HowToKeepPatronsArticle from "./articles/howToKeepPatronsArticle.jsx";
import { Helmet } from "react-helmet";

const articles = [
  {
    id: 1,
    title: `Как сделать заказ в магазине "ПОЛЕ ЧУДЕС"`,
    component: <SecondArticle />,
  },
  {
    id: 2,
    title: "Основная информация о магазине",
    component: <FirstArticle />,
  },
  {
    id: 3,
    title: "Как забрать клад?",
    component: <ThirdArticle />,
  },
  {
    id: 4,
    title: "Доставка через такси",
    component: <FourthArticle />,
  },
  {
    id: 5,
    title: "Гарантии",
    component: <FifthArticle />,
  },
  {
    id: 6,
    title: "Насколько мощный травмат?",
    component: <SixthArticle />,
  },
  {
    id: 7,
    title: "Отказ от изделия",
    component: <SeventhArticle />,
  },
  {
    id: 8,
    title: `Как ухаживать за травматическим оружием?`,
    component: <EiethArticle />,
  },
  {
    id: 9,
    title: `Где можно стрелять из травматического оружия (ОООП)?`,
    component: <NinthArticle />,
  },
  {
    id: 10,
    title: `Типы и калибры патронов для травматического оружия.`,
    component: <CalibreArticle />,
  },
  {
    id: 11,
    title: `Как чистить оружие после покупки (расконсервация)`,
    component: <UncorservationArticle />,
  },
  {
    id: 12,
    title: `Как носить оружие – самообороны?`,
    component: <CarryingWeapon />,
  },
  {
    id: 13,
    title: `Как хранить оружие и патроны?`,
    component: <HowToKeepPatronsArticle />,
  },
];

function ArticleDetail() {
  const { id } = useParams();
  const article = articles.find((article) => article.id === parseInt(id));

  if (!article) {
    return <div>Статья не найдена</div>;
  }

  return (
    <main className="article">
      <Helmet>
        <title>{article.title}</title>
      </Helmet>
      <section className="articleContent">
        <div className="crumbsRow">
          <a href="/articles">Статьи</a>
          <a href="#" className="slash">
            /
          </a>
          <a className="current" href={article.id}>
            {article.title}
          </a>
        </div>
        {article.component}
      </section>
    </main>
  );
}

export default ArticleDetail;
