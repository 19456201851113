import React from "react";

function CarryingWeapon() {
  return (
    <>
      <h1>Как носить оружие – самообороны?</h1>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/12/main.png"}
        alt=""
        className="large"
      />

      <p>
        Ношение оружия осуществляется во время учебных стрельб или в целях
        самообороны. В этом руководстве рассмотрены правила и порядок ношения
        оружия ограниченного поражения (ОООП).
      </p>

      <p>
        При ношении оружия обязательно имейте с собой удостоверение личности и
        разрешение на каждую единицу оружия.
      </p>

      <h3 className="mb-1 mt-4">
        Чем ношение отличается от транспортирования?
      </h3>

      <p>
        Ношение – комплекс действий владельца и одно из состояний оружия,
        которое подразумевает высокую степень готовности к применению или
        использованию. Использование оружия отличается от применения целью: при
        использовании выстрелом из оружия подаётся сигнал, поражается мишень или
        объект охоты; при применении – выстрелом поражается злоумышленник.
      </p>

      <p>
        Принципиальное отличие ношения от транспортирования заключается задачей
        и степенью готовности оружия. При ношении оружие находится в
        расчехлённом состоянии или в кобуре, со снаряжённым магазином или
        барабаном, с пустым патронником и на предохранителе. При
        транспортировании оружие разряжено, зачехлено и находится отдельно от
        патронов; в этом состоянии оно перемещается владельцем «из точки А в
        точку Б».
      </p>

      <h3 className="mb-1 mt-4">Ношение в целях самообороны</h3>

      <p>
        Владелец огнестрельного оружия ограниченного поражения имеет право на
        его ношение в целях самообороны - для защиты жизни, здоровья и
        собственности.
      </p>

      <h3 className="mb-1 mt-4">Как носить ОООП:</h3>

      <ul>
        <li>В кобуре;</li>
        <li>Со снаряженным магазином (вместимостью не более 10 патронов);</li>
        <li>На предохранителе;</li>
        <li>С пустым патронником.</li>
      </ul>

      <p>
        Дослать патрон в патронник можно только в случае необходимости применить
        или использовать оружие. Данное правило не распространяется на
        револьверы и огнестрельные бесствольные устройства отечественного
        производства.
      </p>

      <p>
        Помните, что короткоствольное оружие можно носить только в кобуре.
        Подбирайте кобуру под ваши задачи и стиль одежды. Закон не содержит
        требования об обязательном скрытом ношении ОООП, но для целей
        ежедневного ношения рекомендуем использовать кобуры, которые позволяют
        скрыть оружие от окружающих.
      </p>
    </>
  );
}

export default CarryingWeapon;
