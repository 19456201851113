import React from "react";
import "./css/articlesPage.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ArticleBlocks() {
  const blocks = [
    {
      id: 8,
      title: `Как ухаживать за травматическим оружием?`,
      url: "/articles/8",
      image: "/img/articles/8/main.png",
    },

    {
      id: 9,
      title: `Где можно стрелять из травматического оружия (ОООП)?`,
      url: "/articles/9",
      image: "/img/articles/9/main.png",
    },
    {
      id: 10,
      title: `Типы и калибры патронов для травматического оружия.`,
      url: "/articles/10",
      image: "/img/articles/10/main.png",
    },
    {
      id: 11,
      title: `Как чистить оружие после покупки (расконсервация)`,
      url: "/articles/11",
      image: "/img/articles/11/main.png",
    },
    {
      id: 12,
      title: `Как носить оружие – самообороны?`,
      url: "/articles/12",
      image: "/img/articles/12/main.png",
    },
    {
      id: 13,
      title: `Как хранить оружие и патроны?`,
      url: "/articles/13",
      image: "/img/articles/13/main.png",
    },
    {
      id: 6,
      title: `Насколько мощный травмат?`,
      url: "/articles/6",
      image: "/img/articles/6/main.jpg",
    },
  ];

  return (
    <div className="containerArticles">
      {blocks.map((block) => (
        <div key={block.id} className="articleBlock">
          <div className="imgContainer">
            <img src={process.env.PUBLIC_URL + block.image} alt="" />
          </div>
          <div className="textPart">
            <h3>{block.title}</h3>

            <Link to={block.url}>Перейти</Link>
          </div>
        </div>
      ))}
    </div>
  );
}

function Articles() {
  return (
    <main className="articles">
      <Helmet>
        <title>Статьи</title>
      </Helmet>
      <h2>Статьи</h2>

      <ArticleBlocks />
    </main>
  );
}

export default Articles;
