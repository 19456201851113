import React from "react";

function FourthArticle() {
  return (
    <>
      <h1 id="second">Доставка через такси</h1>

      <img
        src={process.env.PUBLIC_URL + "/img/articles/4/main.png"}
        className="large"
        alt=""
      />

      <p>
        Уважаемые клиенты! Рады сообщить вам об улучшении нашего сервиса
        доставки. Мы с гордостью объявляем о внедрении новой услуги - доставка
        вашего заказа через такси!
      </p>

      <p>
        Теперь вы можете получить свои покупки прямо у себя дома или в удобном
        для вас месте, благодаря надежному и удобному сотрудничеству с
        такси-службой. Однако, обратите внимание, что мы принимаем только
        предоплату за заказы.
      </p>

      <p>
        Почему предоплата? Мы придерживаемся этой политики безопасности для
        защиты как ваших, так и наших интересов. Отправка оружия требует
        дополнительной осторожности, и предоплата позволяет нам обеспечить
        должный уровень безопасности и контроля.
      </p>

      <p>
        Как сделать предоплату? После оформления заказа через нашего менеджера,
        мы предоставим вам информацию об общей сумме и способах оплаты. Вы
        сможете выбрать удобный для вас способ и произвести предоплату, чтобы мы
        могли организовать доставку вашего заказа через такси.
      </p>

      <p>
        Мы понимаем важность безопасности при транспортировке оружия. Мы
        гарантируем, что ваш заказ будет щепетильно упакован и доставлен вам в
        надежных руках.{" "}
      </p>

      <p>
        Доставка через такси - ваш надежный и удобный выбор для получения
        заказов от нашего магазина! Мы гарантируем, что ваш заказ будет
        доставлен вовремя и надежно.
      </p>

      <p>
        Не упустите возможность воспользоваться нашей новой услугой прямо
        сейчас! Если у вас возникли вопросы или вам нужна дополнительная
        информация, не стесняйтесь обратиться к нашей команде. Мы всегда готовы
        помочь и ответить на все ваши вопросы, связанные с нашими услугами и
        доставкой.
      </p>

      <div className="textBlock">
        <p>
          Спасибо за ваше доверие и выбор нашего магазина! <br />
          Команда магазина оружия "Поле Чудес"
        </p>
      </div>
    </>
  );
}

export default FourthArticle;
