import React from "react";

function SixthArticle() {
  return (
    <>
      <h1>Насколько мощный травмат?</h1>

      <h3 className="mt-4 mb-1">Можно ли убить травматом человека?</h3>
      <p>
        Для начала стоит разобраться, что из себя представляет травматический
        пистолет. Название «травмат» укоренилось в быту. В законодательных актах
        такое оружие имеет название огнестрельного, с ограниченным поражением.
        Это пистолет, который носится с целью обеспечить себе безопасность,
        стреляет он на относительно небольшое расстояние. Некоторые модели
        травматических пистолетов разрабатывались на основе специализированного
        оружия для боевых действий. Предназначено для патронов с резиновой
        пулей.
      </p>

      <div className="img2-container mb-2">
        <img src={process.env.PUBLIC_URL + "/img/articles/6/1.jpg"} alt="" />
        <img src={process.env.PUBLIC_URL + "/img/articles/6/2.jpg"} alt="" />
      </div>

      <p>
        Вероятностью убить человека из травматического пистолета, обычно
        интересуются следующие группы лиц:
      </p>

      <ul>
        <li>
          Простые люди, которые хотят защитить себя от всевозможных
          неприятностей.
        </li>
        <li>Начинающие криминальные дельцы.</li>
        <li>Люди с суицидальными мыслями и наклонностями.</li>
      </ul>

      <p>
        Не стоит думать, что такое оружие – вещь безопасная и убить из него
        невозможно. Пуля, которая вылетает из пистолета, имеет довольно высокую
        скорость, приблизительно 400-425 м/с. Масса пули составляет 1гр. При
        этом дульная энергия такого оружия насчитывает около 90 Джоулей. Важно
        учесть и тот факт, что выстрел производится с относительно небольшого
        расстояния.
      </p>

      <p>
        Такой силы достаточно, чтобы повредить кости челюсти или висков. То
        есть, если целиться в голову с близкого расстояния, то можно нанести
        человеку серьезную травму, а можно и убить его.
      </p>

      <p>
        Если выстрел производится с расстояния 2-3 метров, то сила пули
        значительно ослабевает, но он все еще может доставить человеку серьезные
        болевые ощущения. Когда дистанция составляет 5 и более метров, то
        никакого вреда из травмата нанести не получится. Максимум – напугать.
      </p>

      <p>
        Во время конфликтов с использованием оружия, драк или потасовок, часто в
        ход идут удары рукоятью пистолета. В этих случаях стоит помнить, что
        травмат является «подделкой» под боевой пистолет. Исходя из этого можно
        сделать вывод, что при ударе травматическим оружием можно нанести
        человеку увечье, но не убить. Пистолет весит около 800гр, то есть удар в
        висок или нос может быть серьезным. При этом перелом костей
        маловероятен.
      </p>

      <p>
        Владельцам травматического оружия стоит быть внимательными и
        осторожными, соизмерять силу причиненного им вреда с силой отпора,
        который они собираются давать обидчику.
      </p>

      <div className="rowCenter">
        <img src={process.env.PUBLIC_URL + "/img/articles/6/main.jpg"} alt="" />
      </div>

      <h3 className="mt-4 mb-1">Безопасные зоны на теле.</h3>
      <p>
        Когда появилась необходимость самозащиты из травматического пистолета,
        то целится стоит в следующие зоны:
      </p>
      <ul>
        <li>Грудь (исключая область сердца).</li>
        <li>Ноги и руки.</li>
        <li>Ягодицы</li>
        <li>Спина</li>
      </ul>

      <div className="rowCenter">
        <img
          className="mediumImage"
          src={process.env.PUBLIC_URL + "/img/articles/6/3.jpg"}
          alt=""
        />
      </div>

      <div className="textBlock mt-2">
        <p>
          По вопросам приобретения к оператору:
          <a href="https://t.me/YAKUB0VlCH_OPERATORS">@YAKUB0VlCH_OPERATORS</a>
        </p>
      </div>
    </>
  );
}

export default SixthArticle;
