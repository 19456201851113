import React from "react";
import ThirdArticle from "../articlesPage/articles/howToGet.jsx";
import FourthArticle from "../articlesPage/articles/taxiDelivery.jsx";
import "../articlesPage/css/articlePage.css";
import "./css/delivery.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Delivery() {
  return (
    <main className="delivery article">
      <Helmet>
        <title>Доставка</title>
      </Helmet>
      <h1>Доставка</h1>

      <div className="contentWrapper articleContent">
        <div className="content">
          <h3>Содержание</h3>
          <ul>
            <li>
              <a href="#first">Доставка кладом</a>
            </li>
            <li>
              <a href="#second">Доставка через такси</a>
            </li>
          </ul>
        </div>

        <div id="first">
          <ThirdArticle />
        </div>

        <div id="second" className="mt-6">
          {" "}
          <FourthArticle />
        </div>
      </div>
    </main>
  );
}

export default Delivery;
