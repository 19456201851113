import React from "react";
import { useEffect, useState } from "react";
import "./css/home.css";
import CatalogPart from "../../components/catalogPart/catalogPart.jsx";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function FirstScreen() {
  return (
    <section className="firstScreen">
      <div className="blurLayer"></div>
      <div className="left">
        <h1>Добро пожаловать в оружейный магазин "Поле Чудес"</h1>
        <p>
          Ваша безопасность - наш приоритет <br />
          Лучший ассортимент травматического и боевого оружия без бумажной
          волокиты
        </p>

        <div className="buttons-container">
          <Link className="catalogLink" to={"/catalog"}>
            Перейти к товарам
          </Link>
          <a href="https://t.me/YAKUB0VlCH_OPERATORS?text=Доброго%20времени%20суток.%20Я%20с%20сайта%20Поле%20Чудес">
            Оформить заказ
          </a>
        </div>
      </div>
    </section>
  );
}

function SecondScreen() {
  const blocks = [
    {
      text: "Самооборона",
      image: "/img/homePage/self.png",
    },
    {
      text: "Защита близких",
      image: "/img/homePage/home.png",
    },
    {
      text: "Спортивная стрельба",
      image: "/img/homePage/sport.png",
    },
    {
      text: "Развлекательная стрельба",
      image: "/img/homePage/fun.png",
    },
  ];
  return (
    <section className="secondSection">
      <h2>Причины купить оружие</h2>
      <div className="row">
        {blocks.map((block, index) => {
          return (
            <div className="block" key={index}>
              <img src={process.env.PUBLIC_URL + block.image} alt="" />
            </div>
          );
        })}
      </div>
    </section>
  );
}

function ReasonsToWork() {
  const reasons = [
    {
      title: "Анонимность",
      description:
        "Мы используем инновационные способы анонимной оплаты, а так же абсолютно безопасную доставку товара - отработанную годами.",
      image: "/img/homePage/anonymity.svg",
    },
    {
      title: "Большой опыт работы",
      description:
        "Наши специалисты имеют многолетний опыт работы в сфере оружия, а так же постоянно повышают уровень своих знаний и навыков.",
      image: "/img/homePage/experience.svg",
    },
    {
      title: "Высокое качество",
      description:
        "Мы предлагаем только высококачественные товары, проверенные индивидуально нашими специалистами, после этого оружие чистится, смазывается и ждет своей покупки.",
      image: "/img/homePage/quality.svg",
    },
    {
      title: "Надежность",
      description:
        "Мы гарантируем надежность и долговечность наших товаров, обсуждается индивидуально для каждой позиции (уточняйте у оператора в момент покупки)",
      image: "/img/homePage/shield.svg",
    },
  ];
  return (
    <section className="ReasonsToWork">
      <h2>Почему нужно выбрать именно нас</h2>

      <div className="row">
        {reasons.map((item, index) => {
          return (
            <div className="block" key={index}>
              <div className="inRow">
                <img src={process.env.PUBLIC_URL + item.image} alt="" />

                <h3 className="title">{item.title}</h3>
              </div>
              <p>{item.description}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
}

function Home() {
  return (
    <main className="home">
      <Helmet>
        <title>Главная</title>
      </Helmet>
      <FirstScreen />
      <SecondScreen />
      <ReasonsToWork />
      <CatalogPart />
    </main>
  );
}

export default Home;
