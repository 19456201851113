import React from "react";

function ThirdArticle() {
  return (
    <>
      <h1 id="first">Как забрать клад?</h1>

      <img
        src={process.env.PUBLIC_URL + "/img/articles/3/main.jpg"}
        alt=""
        className="large"
      />

      <p>
        Приветствуем вас, уважаемые клиенты нашего оружейного магазина. В данном
        мануале мы покажем, как происходит доставка товара глазами курьера и
        расскажем о всех тонкостях этого дела.
      </p>

      <p>
        Прежде всего хотим сказать, что Поле Чудес - это не просто бизнес, это
        целая семья. Наша команда в ответе за каждого члена персонала, каждого
        клиента, но не смотря на это мы пломбируем каждый заказ индивидуальным
        номером перед выдачей кладмену чтобы соблюдать максимальный контроль
        каждого заказа. Поэтому мы всегда дорожим безопасностью доставки
        какого-либо компонента.
      </p>

      <p>
        В вашу очередь, мы б хотели, что бы вы полностью прислушивались к
        правилам безопасности, которые озвучиваются нами перед тем, как вы
        заберёте товар, это гарантирует долгосрочную работу нашего магазина, а в
        итоге нашего с вами сотрудничества.
      </p>

      <img
        src={process.env.PUBLIC_URL + "/img/articles/3/2.jpg"}
        alt=""
        className="large"
      />

      <p>
        Заказы стараемся выполнять в самые короткие сроки 4-6 часов в крупных
        городах, если заказ был сделан до 15:00
      </p>

      <h3>Как заказать? </h3>

      <ul>
        <li>
          От вас нужна локация где вам будет удобно забрать, улица или район.
        </li>
        <li>Данное место можете прислать скриншотом с карт.</li>
      </ul>

      <p>Если не знаете, кладмен сам выберет, укажите просто район.</p>

      <h3 className="mb-2 mt-4">Как найти клад? </h3>
      <p>По готовности заказа мы выдаём вам:</p>

      <ul>
        <li>5-6 фото локации с разных ракурсов</li>
        <li>Текстовое объяснение как идти, куда смотреть</li>
        <li>Координаты</li>
      </ul>

      <p className="bold">
        По желанию покупателя, кладмен может оставить в радиусе 5м
        опознавательный знак в виде маленькой стрелочки направления,
        нарисованной баллончиком краской.
      </p>

      <img
        src={process.env.PUBLIC_URL + "/img/articles/3/3.jpg"}
        alt=""
        className="large"
      />

      <p>
        Для максимального контроля качества поставляемого товара, каждый заказ
        пломбируется уникальным номером который закрепляется за кладменом{" "}
      </p>

      <p>
        Обратите внимание на особенный механизм для удобства подъёма заказа,
        ниже мы покажем как легко будет и без проблем забрать кейс
      </p>

      <p>
        Сам механизм состоит из колышка + надёжного троса + карабина который
        крепится к кейсу
      </p>

      <p>
        Прикоп делаем в среднем не глубокий, на 1 штык лопаты либо быстрый клад
        для подъема в ближайшие 10 часов (не прикопан, под ветками, листьями и
        тд), но в любом случае наш метод исключает раскопки клада.
      </p>

      <div className="img2-container">
        <img src={process.env.PUBLIC_URL + "/img/articles/3/3-2.jpg"} alt="" />
        <img src={process.env.PUBLIC_URL + "/img/articles/3/3-3.jpg"} alt="" />
      </div>

      <h3 className="mt-4">Как правильно забрать? </h3>

      <ul>
        <li>Подойдите на место прикопа согласно координатам</li>
        <li>Проведите ногой по земле для обнаружения колышка</li>
        <li>Потяните колышек на себя </li>
      </ul>

      <div className="textBlock mb-2">
        <p>
          Анонимность в тёмных делах превыше денег. Способ передачи товара
          кладом является максимально анонимным для обоих сторон - исключает
          деананизацию со всеми вытекающими последствиями.
        </p>
      </div>

      <p>
        За время нашей работы не было ни одного инцидента с облавами на место
        схрона, но не смотря на положительную практику, мы постоянно работаем
        над методами безопасности кладменов при закладе и надёжности тайника от
        вмешательства в передачу товара третьих лиц.
      </p>

      <p>
        Благодаря особому подходу к выполнению заказа мы полностью искоренили
        такие ситуации как ненаход, но на случай ЧП если вы не разобрались на
        месте, вот дополнительная инструкция как нужно действовать:
      </p>

      <p>
        Мануал создан в ознакомительных целях для новеньких клиентов, если вы
        будете соблюдать все изложенные инструкции вы непременно подымете
        желаемое, быстро и не трудно.
      </p>

      <div className="textBlock">
        <p>Приятных покупок в нашем онлайн-магазине "ПОЛЕ ЧУДЕС"!</p>
      </div>
    </>
  );
}

export default ThirdArticle;
