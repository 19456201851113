import React from "react";

function UncorservationArticle() {
  return (
    <>
      <h1>Как чистить оружие после покупки (расконсервация)</h1>
      <img
        src={process.env.PUBLIC_URL + "/img/articles/11/main.png"}
        alt=""
        className="large"
      />

      <p>
        Вы приобрели оружие и привезли его домой. Не используйте оружие до
        расконсервации: консервант используется как способ защиты от коррозии
        при хранении, он не обладает необходимыми для эксплуатации свойствами.
      </p>

      <h3 className="mb-1 mt-4">Расконсервация </h3>

      <p>
        Оружие и комплектующие упаковываются производителем в ингибированную
        плёнку и бумагу. Эти материалы нельзя хранить вблизи отопительных
        приборов, агрессивных веществ; контакт с продуктами питания запрещён.
      </p>

      <p>
        Перед началом расконсервации ознакомьтесь с руководством по эксплуатации
        и распакуйте оружие – наденьте нитриловые перчатки, удалите плёнку,
        бумагу, уберите их в пакет и утилизируйте как прочие отходы. После
        контакта с ингибированными материалами вымойте руки с мылом.{" "}
      </p>

      <h3 className="mb-1 mt-4">Для расконсервации оружия понадобятся: </h3>

      <ul>
        <li>защитные перчатки;</li>
        <li>защитное покрытие рабочего поверхности;</li>
        <li>обезжириватель или растворитель Уайт-спирит;</li>
        <li>нейтральное масло или другой вид оружейной смазки;</li>
        <li>
          цельный шомпол, ёршик, вишер под калибр ствола (часто продаётся
          набором);
        </li>
        <li>ветошь, нетканый материал, патчи для чистки ствола.</li>
      </ul>

      <h3 className="mb-1 mt-4">Как провести расконсервацию</h3>
      <p>Перед началом работ убедитесь, что оружие разряжено!</p>
      <ul>
        <li>Выполните неполную разборку оружия</li>
        <li>
          Удалите видимый консервант с основных частей и комплектующих сухой
          ветошью
        </li>
        <li>
          Обработайте обезжиривателем внешние и внутренние поверхности оружия
        </li>
        <li>Нанесите тонкий слой нейтрального оружейного масла</li>
      </ul>

      <p>
        Канал ствола очистите патчем с обезжиривателем. Если в стволе
        предусмотрено сменное сужение, извлеките его перед чисткой. Каждое
        сужение обработайте обезжиривателем отдельно, тщательно протрите место
        резьбового соединения.
      </p>

      <p>
        После обезжиривания внешних поверхностей, канала ствола, а при наличии –
        и дульных сужений, обработайте их нейтральным оружейным маслом. Не
        храните оружие без установленного сменного дульного сужения.
      </p>

      <p>
        Убедившись, что все детали оружия были расконсервированы и обработаны
        надлежащим образом, соберите оружие, проверьте работу механизмов.
        Уберите оружие в сейф – оно готово к эксплуатации.
      </p>
    </>
  );
}

export default UncorservationArticle;
