import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Header({ headerLinks }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const currentLink = location.pathname;

  function showMobileBurger() {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }

  const handleActiveLink = (link) => {
    if (currentLink === link.href) {
      return "active";
    } else {
      return "";
    }
  };

  return (
    <>
      <header>
        <nav>
          <Link to="/" className="logo">
            П<span className="yellow">0</span>ле Чуд
            <span className="yellow">е</span>с
          </Link>
          <ul>
            {headerLinks.map((link, index) => {
              return (
                <li key={index}>
                  <Link to={link.href} className={handleActiveLink(link)}>
                    {link.text}
                  </Link>
                </li>
              );
            })}
            <li className="mobile">
              <button onClick={showMobileBurger}></button>
              <button onClick={showMobileBurger}></button>
              <button onClick={showMobileBurger}></button>
            </li>
          </ul>
          <div className="container-request">
            <a href="https://t.me/YAKUB0VlCH_OPERATORS?text=Доброго%20времени%20суток.%20Я%20с%20сайта%20Поле%20Чудес">
              Написать оператору
            </a>
          </div>
        </nav>
      </header>

      <div
        className={
          isMenuOpen ? "mobileHeader activeMobileHeader" : "mobileHeader"
        }
      >
        <div className="textPart">
          <Link to="/" className="logo">
            П<span className="yellow">0</span>ле Чуд
            <span className="yellow">е</span>с
          </Link>
          <ul>
            {headerLinks.map((link, index) => {
              return (
                <li key={index}>
                  <Link to={link.href} className={handleActiveLink(link)}>
                    {link.text}
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="container-request">
            <a href="https://t.me/YAKUB0VlCH_OPERATORS?text=Доброго%20времени%20суток.%20Я%20с%20сайта%20Поле%20Чудес">
              Перейти в Telegram
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
