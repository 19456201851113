import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/homePage/home.js";

import Catalog from "./pages/catalogPage/catalog.js";
import CatalogDetail from "./pages/catalogPage/catalogDetail.js";

import Articles from "./pages/articlesPage/articlesPage.js";
import ArticleDetail from "./pages/articlesPage/articleDetail.js";

import Delivery from "./pages/deliveyPage/deliveryPage.js";

import Header from "./components/header/header.jsx";
import Footer from "./components/footer/footer.jsx";

import "./css/reset.css";
import "./css/App.css";

function App() {
  const headerLinks = [
    {
      text: "Главная",
      href: "/",
    },
    {
      text: "Каталог",
      href: "/catalog",
    },
    {
      text: "Статьи",
      href: "/articles",
    },
    {
      text: "О нас",
      href: "/articles/2",
    },
    {
      text: "Доставка",
      href: "/delivery",
    },
    {
      text: "Гарантии",
      href: "/articles/5",
    },
  ];
  return (
    <Router>
      <Header headerLinks={headerLinks} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/catalog/:id" element={<CatalogDetail />} />

        <Route path="/delivery" element={<Delivery />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/articles/:id" element={<ArticleDetail />} />
      </Routes>
      <Footer headerLinks={headerLinks} />
    </Router>
  );
}

export default App;
