import React from "react";
import { Link, useParams } from "react-router-dom";
import TravmatPistolsPart from "./components/travmatPistolsPart.jsx";
import CombatPistolsPart from "./components/combatPistolsPart.jsx";
import CombatRiflesPart from "./components/combatRiflesPart.jsx";
import RevolversPart from "./components/revolversPart.jsx";
import "./css/categoryStyles.css";
import { Helmet } from "react-helmet";
function CatalogDetail() {
  const products = [
    {
      text: "Травматические пистолеты",
      href: "traumatic-pistols",
      component: <TravmatPistolsPart />,
    },
    {
      text: "Боевые пистолеты",
      href: "сombat-pistols",
      component: <CombatPistolsPart />,
    },
    {
      text: "Боевые автоматы",
      href: "сombat-rifles",
      component: <CombatRiflesPart />,
    },
    {
      text: "Револьверы",
      href: "revolvers",
      component: <RevolversPart />,
    },
  ];
  const { id } = useParams();
  const product = products.find((product) => product.href === id);

  if (!product) {
    return <div>Категория не найдена</div>;
  }

  return (
    <>
      <Helmet>
        <title>{product.text}</title>
      </Helmet>
      <main className="catalogDetail">
        <div className="crumbsRow">
          <Link to={"/catalog"}>Каталог</Link>
          <a href="#" className="slash">
            /
          </a>
          <Link className="current" to={`/catalog/${product.href}`}>
            {product.text}
          </Link>
        </div>
        {product.component}
      </main>
    </>
  );
}

export default CatalogDetail;
